<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive
             v-bind:items="monetaries"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>

      <template #cell(clientDetails.clientId)="data">
        <ClientCell @open-client-monetary="handleOpenClientMonetary" :client="clients[data.value]"/>
      </template>

      <template #cell(clientDetails.email)="data">
        {{clients[data.item.clientDetails.clientId]!=null? clients[data.item.clientDetails.clientId].email:'' }}
      </template>
      <template #cell(paymentDetails.processorName)="data">
        {{ data.value }}
      </template>

      <template #cell(clientDetails.country)="data">
        {{clients[data.item.clientDetails.clientId]!=null? clients[data.item.clientDetails.clientId].country:'' }}

      </template>

      <template #cell(depositorUserId)="data">
          {{ getUserFullName(data.value) }}

      </template>

      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>
      <template #cell(businessUnit)="data">
        {{ getBusinessUnitNameById(data.value) }}
      </template>
      <template #cell(paymentDetails.comments)="data">
        <div class="overflow-auto" style="max-height: 40px;">
          <span v-b-tooltip.hover.bottom="data.value">{{ truncateText(data.value) }}</span>
        </div>
      </template>

      <template #cell(registrationTime)="data">
        {{ data.value| momentDateTime }}
      </template>
      <template #cell(isFtd)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }">  {{ data.value ? 'Yes' : 'No' }}</span>
      </template>


      <template #cell(amount)="data">
        <span :style="{ color: data.value > 0 ? '#4ebd52' : '#c53838' }"> {{ convertToEURO(data.value) }}</span>
      </template>
      <template #cell(type)="data">
        <b-badge :variant="getStatusVariant(data.value)">{{ data.value }}</b-badge>
      </template>
      <template #cell(paymentDetails.creditCardNumber)="data">
        <template v-if="data.item.paymentDetails!=null && data.item.paymentDetails.creditCardNumber!=null">
          <span class="mr-1">Card: {{ data.value }}</span>
          <span class="mr-1">Exp: {{ data.item.paymentDetails.creditCardExp }}</span>
          <span class="">CVV: {{ data.item.paymentDetails.creditCardCvv }}</span>
        </template>
      </template>


      <template #cell(realType)="data">
        <span style="font-size:12px" :style="{ color: data.value === 'REAL' ? '#4ebd52' : '#c53838' }"
        >{{
            data.value.toLowerCase()
                .charAt(0)
                .toUpperCase() + data.value.toLowerCase()
                .slice(1)
          }}</span>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>
      <template #cell(clientDetails.affiliate)="data">
        {{ getAffiliateNameById(data.value) }}
      </template>

      <template #cell(status)="data">
        <b-badge variant="transparent" :class="getStatusColorClass(data.value)">{{ data.value }}</b-badge>
      </template>

      <template #cell(actions)="data">

        <div class="btn-group">
          <b-button class="btn btn-info" size="sm" @click="openCommentModal(data.item)" v-b-tooltip.hover
                    title="Comment"
          >
            <i class="fa fa-sticky-note"></i>
          </b-button>

          <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                    class="btn btn-success" size="sm" @click="handleMonetaryEvent(data.item.id,'approve')"
                    v-b-tooltip.hover
                    title="Approve"
          >
            <i class="fa fa-check"></i>
          </b-button>
          <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                    class="btn btn-warning" size="sm" @click="handleMonetaryEvent(data.item.id,'fake')"
                    v-b-tooltip.hover
                    title="Fake Approve"
          >
            <i class="fa fa-check-circle"></i>
          </b-button>
          <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                    class="btn btn-danger" size="sm" @click="handleMonetaryEvent(data.item.id,'decline')"
                    v-b-tooltip.hover
                    title="Decline"
          >
            <i class="fa fa-times"></i>
          </b-button>
          <b-button v-if="data.item.status==='DECLINED'"
                    class="btn btn-danger" size="sm" @click="handleMonetaryEvent(data.item.id,'delete')"
                    v-b-tooltip.hover
                    title="Delete"
          >
            <i class="fa fa-trash"></i>
          </b-button>
        </div>

      </template>

    </b-table>

    <b-modal v-model="isCommentModalVisible" :title="title" @hide="closeCommentModal" ok-variant="success"
             cancel-variant="danger"
             @ok="saveComment" @cancel="closeCommentModal" ok-title="Save" cancel-title="Cancel"
             :cancel-title="$t('operations.cancel')" :ok-title="$t('operations.save')"
    >
      <div class="modal-body">
        <textarea v-model="comment" class="form-control" rows="4"></textarea>
      </div>

    </b-modal>

    <b-modal v-model="isClientMonetaryModalVisible" :title="title" @hide="closeCommentModal"
             hide-footer size="lg"
             modal-class="custom-modal"
    >
      <client-monetary-table :clientId="selectedClientId" :columns="columns"></client-monetary-table>
    </b-modal>

    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
          <b-select-option v-bind:value="250">250</b-select-option>
          <b-select-option v-bind:value="500">500</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.monetaries') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import ClientMonetaryTable from '@/components/tables/ClientMonetariesTable';

export default {
  name: 'MonetaryTable',
  components: {
    ClientMonetaryTable,
    ClientCell,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    monetaries: [],
    initialLoad: true, // Add this flag
    comment: '',
    isCommentModalVisible: false,
    isClientMonetaryModalVisible: false,
    title: '',
    selectedItem: {}, // Store the selected item
    selectedClientId: null, // Store the selected item
    clients: {},
    selected: { all: false },
  }),
  props: {
    transType: {
      required: true
    },
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // Skip the initial load call
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }

        this.pagination.page = 1;
        console.log('refresh was called() from watch handler');
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById','getUserFullName','getAffiliateNameById']),

    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        };
      })
          .sort((a, b) => {
            if (a.key === 'type') return -1; // Move 'type' field to the second position
            if (b.key === 'type') return 1;
            return 0;
          });

      fields = fields.filter(field => !field.key.startsWith('clientDetails'));

      // Create additional fields for clientDetails.accountId and clientDetails.clientId
      const accountIdField = {
        key: 'clientDetails.accountId',
        label: 'AccountId',
        sortable: true,
        active: true,
      };

      const clientIdField = {
        key: 'clientDetails.clientId',
        label: 'Client',
        sortable: true,
        active: true,
      };

// Insert the new fields at the second and third positions
      fields.splice(2, 0, accountIdField, clientIdField);

      const emailField = {
        key: 'clientDetails.email',
        label: 'Email',
        sortable: true,
        active: true,
      };

      const countryField = {
        key: 'clientDetails.country',
        label: 'Country',
        sortable: true,
        active: true,
      };
    /*   const repId = {
        key: 'clientDetails.representativeUserId',
        label: 'Assigend to',
        sortable: true,
        active: true,
      }; */
      const affiliate = {
        key: 'clientDetails.affiliate',
        label: 'Affiliate',
        sortable: true,
        active: true,
      };

      fields = fields.filter(field => !field.key.startsWith('paymentDetails'));

      const creditCard = {
        key: 'paymentDetails.creditCardNumber',
        label: 'Credit Card',
        sortable: true,
        active: true,
      };
      const processorName = {
        key: 'paymentDetails.processorName',
        label: 'Processor name',
        sortable: true,
        active: true,
      };
      fields.splice(5, 0, emailField, countryField, processorName,creditCard, affiliate);

      fields.push({
        key: 'paymentDetails.comments',
        label: 'Comment',
        class: 'text-center',
        sortable: false,
        active: true,
      });
      fields.push({
        key: 'status',
        label: 'Status',
        class: 'text-center',
        sortable: false,
        active: true,
      });
      fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        sortable: false,
        active: true,
      });

      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('monetary', ['getAllMonetary', 'updateMonetary', 'handleEvent', 'exportMonetaryDataExcel']),
    getStatusColorClass(status) {
      // Define BootstrapVue class mappings based on status values
      const classMap = {
        REQUEST: 'text-secondary', // BootstrapVue class for red text
        PENDING: 'text-secondary', // BootstrapVue class for red text
        APPROVED: 'text-success', // BootstrapVue class for green text
        DECLINED: 'text-danger', // BootstrapVue class for green text
        FAKE: 'text-black', // BootstrapVue class for green text
        // Add more status-class mappings as needed
      };

      // Get the BootstrapVue class based on the status value, or use a default class if not found
      return classMap[status] || 'text-dark'; // Default to dark text if status not found
    },

    handleOpenClientMonetary(client) {

      this.selectedClientId = client.id;

      console.log('selectedClientId :', this.selectedClientId);
      this.isClientMonetaryModalVisible = true;
    },

    closeClientMonetaryModal() {
      this.selectedClient = null; // Clear the selected item
      this.isClientMonetaryModalVisible = false;
    },
    async handleMonetaryEvent(id, event) {
      const $this = this;

      await this.handleEvent({
        id,
        event
      })
          .then((updatedMonetary) => {
            const monetaryToUpdate = $this.monetaries.find(monetary => monetary.id === updatedMonetary.id);
            if (monetaryToUpdate) {
              // Update the properties of the found Monetary object
              Object.assign(monetaryToUpdate, updatedMonetary);
              $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.monetaries') }));
              $this.$emit('input', $this.monetaries);
            }
          })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), err.response.data.error);
          });
    },
    openCommentModal(item) {
      this.selectedItem = {
        monetary: item,
        client: this.clients[item.clientDetails.clientId]
      }; // Store the selected item
      this.title = this.getTitle();
      this.comment = item.paymentDetails.comments;
      this.isCommentModalVisible = true;
    },

    closeCommentModal() {
      this.selectedItem = {}; // Clear the selected item
      this.isCommentModalVisible = false;
      this.title = '';
      this.comment = ''; // Clear the comment field
    },

    getTitle() {
      let monetary = { ...this.selectedItem.monetary };
      let client = { ...this.selectedItem.client };
      let title = '';
      if (monetary.clientDetails != null) {
        let accountId = monetary.clientDetails.accountId || "";
        title = accountId + ' ' + client.firstName + ' ' + client.lastName;
      }
      return 'Comment Transaction ' + title;

    },
    async saveComment() {
      let $this = this;
      if (this.selectedItem.monetary.paymentDetails == null) {
        this.selectedItem.monetary.paymentDetails = {};
      }

      this.selectedItem.monetary.paymentDetails.comments = this.comment;
      console.log('Saved Comment for item:');
      await this.updateMonetary({
        monetary: this.selectedItem.monetary,
        id: this.selectedItem.monetary.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.monetaries') }));
      this.$emit('input', this.monetary);
      this.isCommentModalVisible = false;
      this.selectedItem = {}; // Clear the selected item
      this.comment = ''; // Clear the comment field

    },

    truncateText(text) {
      const maxLength = 40; // Adjust the maximum length as needed
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    },
    getStatusVariant(status) {
      switch (status) {
        case 'DEPOSIT':
          return 'success';
        case 'CARD':
          return 'warning';
        case 'WITHDRAWAL':
          return 'danger';
        default:
          return 'primary';
      }
    },
    async refresh() {
      let $this = this;

      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllMonetary(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.monetaries = result.data['monetaryTransactions'];
      this.clients = result.data['clients'].reduce((map, obj) => (map[obj.id] = obj, map), {});
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    },
    createExcelFile() {
      let $this = this;
      if ($this.exportingInProgress) {
        this.showWarningNotification('Exporting data to Excel is already in progress. Please wait...');
        return;
      }
      this.showInfoNotification('Exporting data to Excel...');
      this.exportingInProgress = true;
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      this.exportMonetaryDataExcel({
        pagination: this.pagination,
        sort,
        filter: this.filters,
      }).finally(() => {
        $this.exportingInProgress = false;
      });
    },
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.overflow-auto {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
